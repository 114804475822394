@function hexToRgb($hex) {
  $r: red($hex);
  $g: green($hex);
  $b: blue($hex);
  @return $r, $g, $b;
}
// custom scss-global-variable start
//#e92b2e, #41b653, #5591c5, #f7bb1a
:root {
  // HEX color code
  --theme-primary: #f7bb1a;
  --theme-secondary: #2D2E4A;
  --theme-btn: #f7bb1a;
  --theme-primary-black: #ffffff;
  --theme-background: #fafafb;
  --theme-title: #3f3f3f;
  --theme-text: #2d2e4a;
  --theme-white: #ffffffe2;
  --theme-color-white: #ffffff;

  // --theme-white: #ffffff;
  --theme-chart-secondary: #ffdd6b;
  --theme-light-primary: #d1e2e7;
  --theme-danger: #e41212;
  --theme-text-light: #eaeaea;
  --theme-light-grey: #f9f9f9;
  --theme-dark-grey: #f6f6f6;
  --theme-black: #000000;
  --line-color: #e4e4e4;
  --theme-border: #e4e4e4;
  --theme-transition: all 0.3s ease 0s;
  --input-border: transparent;
  --light-border: #eff0f6;
  --shadow-color: #14142b14;

  // RGBA color code
  --rgba-light-grey: hexToRgb(--theme-light-grey);
  --rgba-white: hexToRgb(--theme-white);
  --rgba-primary: hexToRgb(#f7bb1a);

  //new code
  --theme-active-bg: #f9f9f9;
  --theme-icon-color: #f7bb1a;
  --theme-text: #7e868f;
  --theme-white: #ffffff;
  --theme-active-pagination: #ffffff;
  --theme-pagination-bg: #f7f7f8;
  --theme-link-color: #6c6d81;
  --plan-text-color: #6f6c8f;
}
// custom scss-global-variable end

// scss-docs-start bootstrap-theme-color-variables

$yellow: #f7bb1a;
$primary-yellow: #f7bb1a;
$dark-grey: #242424;
$black: #1a1a1a;
$light-grey: #f9f9f9;
$light-dark-grey: #f6f6f6;
$underlineColor: #e4e4e4;
$white: #ffffffe6;
$grey: #323232;
$input-border: #eaeaea;
$ng-text: #46515e;
$chart-secondary: #f7bb1a;
$table-border: #e4e4e4;
$input-disabled: #e4e4e4;

$modal-backdrop: #0000008a;
$primary: $yellow;
$secondary: $dark-grey;
$dropdown-link-hover-bg: $light-grey;
$dropdown-link-active-bg: $light-grey;
$btn-close-color: $primary;
$border-color: $underlineColor;
$link-decoration: none;

// bootstrap-theme-table-variables
$table-striped-bg: #fcfcfc;
$table-bg: $white;
$table-color: #bababa;
$table-striped-color: #bcbcbc;
$table-border-color: $table-border;

// bootstrap-theme-input-variables
$input-bg: $white;
$input-disabled-bg: $input-disabled;
$input-color: $grey;
$input-placeholder-color: #46515e7e;
$input-border-color: $input-border;
$input-focus-border-color: #d1d1d1;
$input-padding-y: 14px;
$input-padding-x: 20px;

// bootstrap-theme-modal-variables
$modal-content-bg: white;
$modal-content-border-radius: 12px;
$modal-backdrop-opacity: 1;
$modal-backdrop-bg: $modal-backdrop;
$modal-content-color: $black;

// Ng-select theming
$ng-select-bg: $white;
$ng-select-border: transparent;
$ng-select-dropdown-option-text: $black;
$ng-select-input-text: $ng-text;
$ng-select-primary-text: $ng-text;
$ng-select-height: 48px;
$ng-select-border-radius: 8px;
$ng-select-selected: $chart-secondary;
$ng-select-selected-text: $white;
$ng-select-marked: $chart-secondary;
$ng-select-box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
  rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

//Dropdown
$dropdown-bg: white;
