/* You can add global styles to this file, and also import other style files */

@import './variables.scss';
@import '../node_modules/bootstrap/scss/bootstrap';
@import '../node_modules/@ng-select/ng-select/scss/default.theme.scss';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}
html.rtl {
  body {
    direction: rtl;
  }
}
body {
  padding: 0;
  margin: 0;
  background-color: var(--theme-light-grey);
  color: var(--theme-secondary);
  direction: ltr;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

img,
video,
audio,
iframe {
  max-width: 100%;
  display: block;
}

input.form-control,
input {
  font-size: 16px;
  line-height: 19px;

  @media screen and (max-width: 575px) {
    font-size: 14px;
    line-height: 17px;
  }

  &::-webkit-input-placeholder {
    color: var(--theme-text) !important;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.form-control {
  &:focus,
  &:focus-within {
    outline: none;
    box-shadow: none;
    border-color: #ced4da;
  }
}

.btn {
  padding: 14px 15px;
  font-size: 16px;
  line-height: 19px;
  border-radius: 8px;
}

.btn-primary {
  color: var(--theme-color-white);
  white-space: nowrap;
}

.modal-header {
  padding-bottom: 16px;
  border-bottom: 1px solid var(--line-color);

  .modal-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #1a1a1a;
  }

  .btn-close {
    padding: 8px;
    cursor: pointer;
    color: var(--theme-btn);
    border-radius: 100%;
    font-size: 12px;
    border: 1.5px solid var(--theme-btn);
    opacity: 1;
    &.rtl{
      margin-right: auto;
      margin-left: 0;
    }
  }
}

.modal-body {
  padding: 24px 0;
  border-radius: 12px;
}

.modal-footer {
  justify-content: center;

  .btn {
    padding: 16px 32px;

    & + .btn {
      margin-left: 16px;
    }
  }
}

.auth-wrapper {
  padding: 15px;
  display: flex;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  justify-content: center;

  .auth-inner-wrapper {
    margin: 0 auto;
    border-radius: 22px;
    width: 100%;

    @media screen and (max-width: 991px) {
      border-radius: 10px;
    }

    .auth-logo {
      margin-bottom: 50px;

      @media screen and (max-width: 575px) {
        margin-bottom: 30px;
      }
    }
  }
}

.auth_form {
  .form-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.02em;
    margin-bottom: 15px;

    @media screen and (max-width: 991px) {
      font-size: 20px;
      line-height: 25px;
    }
  }

  .form-subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: var(--theme-text);
    margin-bottom: 32px;

    @media screen and (max-width: 767) {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 575px) {
      font-size: 14px;
      line-height: 19px;
    }
  }

  .remeber-me {
    cursor: pointer;
    color: var(--theme-text);

    input[type='checkbox'] {
      cursor: pointer;
      width: 15px;
      height: 15px;
      vertical-align: -2px;
      margin-right: 8px;
    }
  }

  .btn-primary {
    min-width: 190px;
  }
}

.form-group {
  margin-bottom: 20px;

  .form-label {
    display: block;
    // color: var(--theme-primary);
    margin-bottom: 12px;
    line-height: 19px;

    @media screen and (max-width: 575px) {
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 8px;
    }
  }

  .form-control {
    width: 100%;
  }
}

.detail-wrapper {
  margin-bottom: 24px;

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
  }

  .detail-title {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 10px;
  }

  .detail-inner-wrap {
    display: flex;
    flex-wrap: wrap;
    
    .no-img {
      label {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: var(--theme-text);
        margin-bottom: 8px;
      }
    }

    .request-info {
      margin-bottom: 20px;
      max-width: 33.33%;
      flex: 0 0 33.33%;
      padding: 0 10px 0 0;

      label {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: var(--theme-text);
        margin-bottom: 8px;
      }

      h6 {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 0;
        word-break: break-word;
      }
      &.cid{
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    .document-info {
      max-width: 20%;
      flex: 0 0 20%;
      // margin-bottom: 20px;

      img {
        width: 96px;
        object-fit: cover;
        margin-bottom: 6px;
      }

      h6 {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        word-break: break-word;
      }
    }
  }
}

// ::ng-deep {
//   .ng-select.ng-select-single {
//     .ng-select-container {
//       width: 100%;
//       height: 48px;
//       border: 1.5px solid #eaeaea;
//       border-radius: 8px;
//       font-weight: 400;
//       font-size: 16px;
//       line-height: 19px;
//     }
//   }
// }
// .ng-select.ng-select-single .ng-select-container,
// .ng-select.ng-select-multiple .ng-select-container {
//   min-height: 48px;
//   border: 1.5px solid #eaeaea;
//   border-radius: 8px;
//   font-weight: 400;
//   font-size: 16px;
//   line-height: 19px;
// }
// .ng-select.ng-select-multiple .ng-select-container {
//   display: flex;
//   align-items: center;
//   height: auto;
//   .ng-value-container {
//     .ng-placeholder {
//       padding: 9px;
//     }
//     .ng-input {
//       padding: 0;
//     }
//     .ng-value {
//       padding: 6px;
//       background: var(--theme-primary);
//       border-radius: 8px;
//       .ng-value-icon {
//         &:hover {
//           background-color: var(--theme-primary);
//           opacity: 0.5;
//         }
//       }
//     }
//   }

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  // padding: 0;
  color: var(--ng-text);
}

// }
.base-card {
  background-color: var(--theme-primary-black);
  border-radius: 12px;
  padding: 23px;

  @media screen and (max-width: 1199px) {
    padding: 15px;
  }
}

// Switch input

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: var(--theme-primary);
    }

    &:focus + .slider {
      box-shadow: 0 0 1px var(--theme-primary);
    }

    &:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--theme-text);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;

    &:before {
      border-radius: 50%;
      position: absolute;
      content: '';
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
}

.first-row {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 17px;
  & .form-control-out {
    max-width: 325px;
    width: 100%;
    color: var(--theme-icon-color) !important;

    .form-control {
      border-radius: 8px !important;
    }
  }

  & .btn-primary {
    white-space: nowrap;
  }
}

.ng-select {
  z-index: 9;
}

.ngb-datepicker-month {
  .ngb-dp-week .ngb-dp-weekdays {
    background-color: var(--theme-white);
  }
}

.password-label-field {
  position: relative;
  .eye {
    position: absolute;
    right: 24px;
    top: 13px;
    cursor: pointer;
  }
  input{
    padding-right: 56px;
  }
  &.rtl{
    input{
      padding-left: 48px;
      padding-right: 16px;
    }
    .eye{
      right: unset;
      left: 18px;
    }
  }
}

// .ng-select.ng-select-single .ng-select-container {
//   width: 100%;
//   height: 48px;
//   border: 1.5px solid var(--theme-text-light);
//   border-radius: 8px;
//   font-weight: 400;
//   font-size: 16px;
//   line-height: 19px;
//   cursor: pointer;
// }
// .ng-dropdown-panel {
//   padding: 12px 0 12px 0;
//   z-index: 9 !important;
//   .ng-dropdown-panel-items .ng-option.ng-option-marked {
//     background-color: var(--theme-white);
//     color: var(--theme-primary);
//   }
//   .ng-dropdown-panel-items .ng-option {
//     &:not(:last-child) {
//       border-bottom: 1px solid var(--line-color);
//     }
//   }
// }

.swal2-container {
  z-index: 10000 !important;
}

.new-chip {
  position: absolute;
  top: 2px;
  right: 3px;
  background-color: var(--theme-primary);
  color: var(--theme-white);
  font-size: 10px;
  padding: 4px;
  border-radius: 0 5px 0 6px;
}

.wraper-details {
  border-bottom: 1px solid var(--line-color);
  margin-bottom: 10px;
}

.decline-reason {
  margin-bottom: 20px;
  padding: 0 10px 0 12px;

  label {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--theme-text);
    margin-bottom: 8px;
  }

  h6 {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 0;
    word-break: break-word;
  }
}

.cursor-pointer {
  cursor: pointer;
}

// New Styling

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: var(--theme-text) transparent transparent;
  border-top-color: var(--theme-text) !important;
}
.ng-select {
  .ng-input {
    cursor: pointer;
  }
  .ng-select-container {
    &:hover {
      box-shadow: none;
    }
  }
}
.alert-cancel {
  order: 1;
}
.alert-confirm {
  order: 2;
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  border-color: transparent transparent var(--theme-primary);
}

.btn-primary {
  --bs-btn-active-bg: #{shade-color(#f7bb1a, 25%)};
  --bs-btn-hover-bg: #ffd13b;
  --bs-btn-hover-color: #ffffff !important;
  --bs-btn-active-color: #ffffff !important;
  --bs-btn-disabled-color: #ffffff;
}

.modal-content {
  padding: 24px;
}

.tab-group {
  width: 296px;
  height: 56px;
  background: transparent;
  border-radius: 8px;
  border: 1px solid var(--line-color);
  align-items: center;
  padding: 4px;

  .tab-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 145px;
    height: 48px;
    cursor: pointer;
    gap: 10px;

    &.active {
      border-radius: 6px;
      background-color: var(--theme-btn);
      color: var(--theme-primary-black);
    }
  }
}

.title-text {
  color: var(--theme-title);
}

.detail-nav-list {
  gap: 12px;
  .nav {
    border: 1px solid var(--theme-text-light);
    border-radius: 4px;
    margin-right: 24px !important;
    width: 185px;

    .nav-link {
      padding: 12px 20px;
      color: var(--theme-text);
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      border: 1px solid var(--theme-border);
      border-radius: 0;

      &.active {
        background-color: transparent;
        // color: var(--theme-primary);
        position: relative;

        &::before {
          position: absolute;
          content: '';
          top: 50%;
          left: 0;
          width: 4px;
          height: 18px;
          transform: translateY(-50%);
          background-color: var(--theme-btn);
          border-radius: 0 10px 10px 0;
        }
      }
    }
    &.rtl{
      margin-right: 0 !important;
      margin-left: 24px;
    }
  }

  .tab-content {
    .tab-pane {
      .tab-detail {
        h3 {
          color: var(--theme-title);
          font-size: 20px;
          font-weight: 500;
        }

        .images-tab {
          cursor: pointer;
          width: 124px;
          margin-right: 10px;
          height: 124px;
          border-radius: 13px;
          display: inline-block;
          overflow: hidden;
          &.rtl{
            margin-right: 0px;
            margin-left: 10px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        p {
          font-size: 14px;
          color: var(--theme-text);
        }
      }
    }
  }
}

button.swal2-confirm.alert-confirm.swal2-styled.swal2-default-outline {
  background-color: var(--theme-primary) !important;
}

.ng-select .ng-clear-wrapper {
  color: red;
}
.flex-1{
  flex: 1 1 0;
}
.divider{
  position: relative;
  width: 100%;
  &::before{
    position: absolute;
    content: "";
    height: 0;
    width: 100%;
    border:1px dashed #f3f0f0;
    top: 50%;
    transform: translateY(-50%);
  }
  span{
    height: 34px;
    border-radius: 50%;
    width: 34px;
    line-height: 34px;
    margin: 0 auto;
    display: block;
    background-color: #f3f0f0;
    position: relative;
    z-index: 9;
    text-align: center;
  }
}

.custom-border-select {
  &.ng-dropdown-panel {
    border: 1px solid var(--theme-primary);
    box-shadow: 0 7px 10px var(--theme-text);
  }
}

.search-control{
  .search-icon{
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
  &.rtl{
    input{
      padding-right: 12px;
      padding-left: 40px;
    }
    .search-icon{
      right: unset;
      left: 8px;
    }
  }
}

.api-switch {
  position: relative;
  display: inline-block;
  width: 49px;
  height: 28px;
  &.rtl{
    span{
        left: 54px;
        right: 0;
    }
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: var(--theme-primary);
    }

    &:focus + .slider {
      box-shadow: 0 0 1px var(--theme-primary);
    }

    &:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 54px;
    bottom: 0;
    background-color: var(--theme-text);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;

    &:before {
      border-radius: 50%;
      position: absolute;
      content: '';
      height: 22px;
      width: 22px;
      left: 3px;
      bottom: 3px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
}